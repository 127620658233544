import React from "react"
import styled from "styled-components"
import Seo from "../components/seo"
import Layout from "../components/layout"
import sparkles from "../images/sparkles.png"

const Main = styled.main`
  padding: 8rem 0;
  .success {
    font-weight: 700;
    font-size: 2.4rem;
  }
  p {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  img {
    margin-bottom: 0px;
    height: 16px;
  }
`

const ThankYouPage = () => {
  return (
    <Layout>
      <Seo title="Obrigado!" />
      <Main>
        <div className="container">
          <p className="success">Mensagem recebida!</p>
          <p>
            Obrigado pelo teu interesse. Se fores aceite, receberás um email com
            as condições e os próximos passos no prazo de 24 horas.
          </p>
          <p>
            See you soon! <img src={sparkles} alt="sparkles icon" />
          </p>
        </div>
      </Main>
    </Layout>
  )
}

export default ThankYouPage
